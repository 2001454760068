<template>
  <!-- CART DROPDOWN -->
  <vs-dropdown vs-custom-content vs-trigger-click class="cursor-pointer" :key="dropdownKey">
    <feather-icon icon="ShoppingCartIcon" class="cursor-pointer ml-4 mr-6 mt-1 text-primary2" :badge="cartItemsQty" />
    <vs-dropdown-menu class="cart-dropdown vx-navbar-dropdown" :class="{ 'dropdown-custom': cartItems.length }">
      <!-- IF CART HAVE ITEMS: HEADER -->
      <template v-if="cartItems.length">
        <div class="notification-header text-center px-5 py-4 bg-primary text-white">
          <h3 class="text-white">
            {{ cartItems.length }} Item<span v-show="cartItems.length > 1">s</span>
          </h3>
          <p class="opacity-75">In Your Cart</p>
        </div>

        <!-- CART ITEMS -->
        <VuePerfectScrollbar ref="mainSidebarPs" class="scroll-area--cart-items-dropdowm p-0 mb-10"
        
        :settings="settings"
          :key="$vs.rtl">
          <ul class="bordered-items">
            <li v-for="item in cartItems" :key="item.objectID" class="flex items-center space-x-4 cart-item cursor-pointer"
              :class="{ 'is-hidden': item.product.hideStatus && inActiveCartItems.includes(item) }"
              @click="productDetail(item.productStoreId)">
              <!-- IMG COL -->

              <img :src="item.images[0].image" alt="item" class="cart-dropdown-item-img w-24 h-24 object-contain p-2" v-if="item.images.length > 0"/>

              <!-- INFO COL -->
              <div class="flex flex-col justify-center flex-1 truncate">
                <span class="font-medium block cart-item-title truncate">{{item.name}}</span>
                <div class="flex items-center justify-between">
                  <span class="text-sm font-medium">{{ item.quantity }} <small>x</small> {{ currency }}
                    {{ item.price | formatDecimals }}</span>
                </div>
              </div>

              <feather-icon icon="XIcon" svgClasses="h-6 w-6 cursor-pointer text-danger bg-red-100 rounded-full p-1 mx-4" class="hover:text-danger pointer-events-auto"
                @click.stop="removeItemFromCart(item)" />

            </li>
          </ul>
        </VuePerfectScrollbar>
        <div class="
            checkout-footer
            fixed
            bottom-0
            rounded-b-lg
            text-primary
            font-semibold
            w-full
            p-2
            text-center
            border border-b-0 border-l-0 border-r-0 border-solid
            d-theme-border-grey-light
            cursor-pointer
          " @click="viewCheckout">
          <span class="flex items-center justify-center">
            <feather-icon icon="ShoppingCartIcon" svgClasses="h-4 w-4" />
            <span class="ml-2">Checkout</span>
          </span>
        </div>
      </template>

      <!-- IF CART IS EMPTY -->
      <template v-else>
        <p class="p-4">Your Cart Is Empty.</p>
      </template>
    </vs-dropdown-menu>
  </vs-dropdown>
</template>

<script>
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import { mapActions, mapMutations } from "vuex";

export default {
  components: {
    VuePerfectScrollbar,
  },
  data() {
    return {
      organizationId: null,
      dropdownKey: 0,
      settings: {
        // perfectscrollbar settings
        maxScrollbarLength: 60,
        wheelSpeed: 0.6,
      },
      currency: process.env.VUE_APP_CURRENCY,
      userTypeAdmin: undefined
    };
  },
  computed: {
    // CART DROPDOWN
    cartItems() {
      return [
      ...this.$store.state.eCommerce.inactiveCartItems.slice().reverse(),
      ...this.$store.state.eCommerce.cartItems.slice().reverse()
    ]},

    inActiveCartItems() {
      return [
      ...this.$store.state.eCommerce.inactiveCartItems.slice().reverse(),
    ]},


    cartItemsQty() {
      let items = this.cartItems;
      let count = 0;

      items.map(item => {
        count += item.quantity
      })

      return count
    }
  },
  async created() {
    await this.getCartItems();
    this.userTypeAdmin = this.$store.state.AppActiveUser.userType
  },
  methods: {
    ...mapActions("ecommerce", ["getCartItemsFromApi"]),
    ...mapActions("organization", ["fetchOrganizationClinicsList"]),
    ...mapMutations(["UPDATE_CLINIC_ID"]),
    removeItemFromCart(item) {
      this.$store.dispatch("eCommerce/toggleItemInCart", item);
    },
    getClinics() {
      this.fetchOrganizationClinicsList({ id: this.organizationId, limit: 100 })
        .then((res) => {
          let results = res.data.data.docs.filter(({ showStore }) => showStore);
          if (sessionStorage.getItem("doctorClinicSelectedClinic")) {
            results = results.filter(
              ({ _id }) =>
                _id === sessionStorage.getItem("doctorClinicSelectedClinic")
            );
          } else {
            if (results.length) {
              sessionStorage.setItem(
                "doctorClinicSelectedClinic",
                results[0]._id
              );
            }
          }
          if (results.length) {
            this.getCartItemsFromApi(results[0]._id);
            const hours = results[0].clinicOpeningHours;
            let operations = {};
            if (Object.keys(hours).length) {
              Object.keys(hours).map((k) => {
                if (k !== "_id") {
                  operations[k.slice(0, -1)] = hours[k];
                }
              });
            }
            this.UPDATE_CLINIC_ID({
              clinicId: results[0]._id,
              clinic: [results[0]],
              operations,
            });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getCartItems() {
      this.getCartItemsFromApi();
    },
    viewCheckout() {
      this.dropdownKey = Math.random();
      let role = ''

      if(this.userTypeAdmin && this.userTypeAdmin === 'superAdmin') {
        role = 'superAdmin'

      } else {
        role = localStorage.getItem(`${process.env.VUE_APP_ACCESS_TOKEN_NAME}Role`);

        if(role.toLowerCase() === 'staff') {
          const user = JSON.parse(localStorage.getItem(`${process.env.VUE_APP_ACCESS_TOKEN_NAME}User`));
          role = user.userType;
        }
      }
      const route = {
        'superAdmin': `/super-admin/store/checkout`,
        'nurse': `/nurse/checkout`,
        'basic': '/basic/checkout',
        'doctor': '/doctor/checkout',
        'Org Owner': `/org-owner/checkout`
      }

      this.$router.push(route[role]).then(async () => {
        this.$vs.loading();
        await this.getCartItemsFromApi(
          sessionStorage.getItem("doctorClinicSelectedClinic")
        )
        this.$vs.loading.close();
      }).catch((err) => console.log(err));
    },
    productDetail(id) {
      let role = ''
      
      if(this.userTypeAdmin && this.userTypeAdmin === 'superAdmin') {
        role = 'superAdmin'

      } else {
        role = localStorage.getItem(`${process.env.VUE_APP_ACCESS_TOKEN_NAME}Role`);

        if(role.toLowerCase() === 'staff') {
          const user = JSON.parse(localStorage.getItem(`${process.env.VUE_APP_ACCESS_TOKEN_NAME}User`));
          role = user.userType;
        } 
      }
      const route = {
        'superAdmin': `SuperAdminProductDetail`,
        'nurse': `NurseProductDetail`,
        'basic': `BasicProductDetail`,
        'doctor': `DoctorProductDetail`,
        'Org Owner': `OrgOwnerProductDetail`
      }

      // let routerName = "";

      // if(this.userTypeAdmin && this.userTypeAdmin === "superAdmin") {
      //   routerName = "SuperAdminProductDetail"

      // } else if(this.$store.state.AppActiveUser.userType === "doctorClinic") {
      //   routerName = "DoctorClinicProductDetail";

      // } else {
      //   routerName = "NurseProductDetail";
      // }

      this.$router
        .push({
          name: route[role],
          params: { id },
        })
        .catch((err) => console.log(err));
    },
  },
  watch: {
    "$store.state.clinicId": function () {
      this.getCartItems();
    },
    organizationId: function (val, oldVal) {
      if (!oldVal) {
        this.getClinics();
      }
    },
  },
  beforeMount: async function () {
    if (
      localStorage.getItem(`${process.env.VUE_APP_PROCESS_TOKEN}OrganizationId`)
    ) {
      this.organizationId = localStorage.getItem(
        `${process.env.VUE_APP_PROCESS_TOKEN}OrganizationId`
      );
    }
  }
};
</script>
<style>
.con-vs-dropdown--menu.cart-dropdown {
  position: fixed;
  top: 48px !important;
}

.is-hidden {
  opacity: 0.3;
  pointer-events: none;
}
</style>
